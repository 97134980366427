<template>
  <div>
    <el-table :data="data" border empty-text="暂无待续费的项目">
      <el-table-column label="项目">
        <template v-slot="scope">
          {{ scope.row.project.name }} {{ scope.row.remark}}
        </template>
      </el-table-column>
      <el-table-column prop="start_at" label="开始时间"></el-table-column>
      <el-table-column label="到期时间">
        <template v-slot="scope">
          <b style="color:red;" v-text="scope.row.end_at"></b>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="总金额"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="success" size="small" @click="clickRenew(scope.row, 1)">已续</el-button>
          <el-button type="danger" size="small" @click="clickRenew(scope.row, 0)">不续</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>


<script>
  import {apiGetProjectNumbersPaddingRenew} from "@/api/projectNumbersApi"
  import {apiRenewProjectContract} from "@/api/projectContractApi"
  import {getCompanyId} from "@/plugins/company"

  export default {
    created () {
      this.getData()
    },
    data(){
      return {
        data: []
      }
    },
    methods: {
      getData() {
        apiGetProjectNumbersPaddingRenew({
          company_id: getCompanyId()
        }).then(res => {
          this.data = res
        })
      },
      // 操作是否续费
      clickRenew(row, renew) {
        this.$confirm('是否标识为' + (renew ? '续费' : '不续费')).then(() => {
          apiRenewProjectContract(row.id, {renew: renew}).then(() => {
            this.$message.success('操作成功')
            this.getData()
          })
        })
      }
    }
  }
</script>
